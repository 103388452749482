import React from "react"
import success from "../../assets/images/check-mark.svg"

let title = "Thank You"
let subTitle = "We'll contact you shortly."

const FormSuccess = props => {
  return (
    <div className="row">
      <div className="col">
        <div className="form-feedback">
          <img src={success} alt="success icon" />
          <h3>{props.title || title}</h3>
          <p>{props.subTitle || subTitle}</p>
        </div>
      </div>
    </div>
  )
}

export default FormSuccess
